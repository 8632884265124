import React from 'react';
import createReactClass from 'create-react-class';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'semantic-ui-css/semantic.min.css'
import './App.css';
// import './Custom.css';
import { Dropdown, Icon, Image, Menu } from 'semantic-ui-react'
import {Switch, Route, Link, withRouter, BrowserRouter} from "react-router-dom";  // Link
// import { Home } from './pages/AllPages.js';
import Home from './menu/Home';
import 'semantic-ui-css/semantic.min.css'
import { Modal, Comment, Confirm, Header } from 'semantic-ui-react';
import { ModalHeader, ModalBody, ModalFooter, Input } from 'reactstrap';

import Abouts from './menu/Abouts.js';
import Greetings from './menu/Greetings.js';
import Intro from './menu/Intro';
import Ceo from './menu/Ceo.js';
import Teachers from './menu/Teachers';
import Program from './menu/Program';
import Infants from './menu/Infants.js';
import Kinder from './menu/Kinder.js';
import Elem from './menu/Elem.js';
import TimeTable from './menu/TimeTable.js';
import Register from './menu/Register.js';
import Location from './menu/Location.js';
import Announce from './menu/Announce.js';
import Signup from './menu/Signup';
import Login from './Login';
import AuthenticationService from './AuthenticationService';

import { AllStyles } from './styles/AllStyles';
import { isMobile } from './styles/AllStyles';

import { Button, Navbar, Nav, NavDropdown, Form, FormControl, MenuItem } from 'react-bootstrap';
import * as ReactBootStrap from "react-bootstrap";
import ReactCSSTransitionGroup from 'react-transition-group';
import TextField from "@material-ui/core/TextField";
import { PageTransition } from "@steveeeie/react-page-transition";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import Constants from "./Constants";
import axios from "axios";
// import { Navbar, NavbarItems, NavItem, NavDropdown, MenuItem, Nav, Form, FormControl, Button } from 'react-bootstrap';
// import { LinkContainer } from "react-router-bootstrap";

const menuMain = '연세 유아/아동 놀이 교육원';
const menuHome = '인사말';
const menu1Abouts = '기관 소개';
const menu1Greetings = '인사말';
const menu1Ceo = '대표소개';
const menu2Teachers = '교육원소개';
const menu3Programs = '교육프로그램';
const menu3_5Programs = '교육활동';
const menu3Infant = '유아반';
const menu3Kinder = '유치반';
const menu4TimeTable = '교육시간표';
const menu5Register = '교육신청';
const menu5aRegisterClass = '일반교육';
const menu5bRegisterConsult = '상담신청';
const menu6Community = '열린마당';
const menu6Location = '찾아오시는길';
const menu6Announce = '공지사항';
const menuLogin = '로그인';
const menuSignup = '회원가입';
const menuAdminIp = 'IP';

const url1Intro = '/intro';
const url1Abouts = '/abouts';
const url1Greetings = '/greetings';
const url1Ceo = '/ceo';
const url2Teachers = '/teachers';
const url3Prog = '/program';
const url3ProgInfant = '/infants';
const url3ProgKinder = '/kinder';
const url3ProElem = '/elem';
const url3_5Activity = '/activity';
const url4TimeTable = '/timetable';
const url5Register = '/register';
const url5aRegisterClass = '/registerClass';
const url5bRegisterConsult = '/registerConsult';
const url6Location = '/location';
const url6Announce = '/announce';
const urlSignup = '/signup';

const menuMaps = 'Maps';
const menuData = 'Data';

const urlSurvey = '/mvpsurvey';
const urlSummary = "/resshort";
const menuHistory = 'History';
const urlHistory = "/adresults";
const menuMapbox = "Mapbox";
const urlMapbox = "/maps";
const menuOpenl = 'Openlayers';
const urlOpenl = "/eng/openl";
const menuLeaf = 'Leaflet';
const urlLeaf = "/eng/leaf";

const menuCharts = "Charts";
const urlCharts = "/charts";
const mapboxMap = "/MapboxMap";
const urlAddetails = "/addetails";

const menuCreatedata = 'Create Data';
const urlCreatedata = '/eng/createdata';
const menuImportdata = 'Import Data';
const urlImportdata = '/eng/importdata';
const menuGeotools = 'Geo Tools';
const urlGeotools = '/eng/geotools';


const menuComments = "Comments";
const urlComments = "/comments";
const urlModal = "/modal";

const urlDeckgl = "/eng/deckgl";

const navMenuLogin = (<div style={{position: 'absolute', marginLeft: '-80px'}} >{Constants.menuLogin}&nbsp;&nbsp;<Icon name='sign-in'> </Icon></div>);

// let CSSTransitionGroup = React.addons.CSSTransitionGroup;
// createReactClass

class NavbarInstance extends React.Component {

    // getInitialState: function() {
    //     return {activeItem: ''};
    // },

    isAdmin = false;
    constructor(props) {
        super(props);
        let isLoggedIn = AuthenticationService.isUserLoggedIn();
        this.state = {
            showFirstMenu: false,
            showFirstMenuSub: false,
            showSecondMenu: false,
            showThirdMenu: false,
            showFourthMenu: false,
            showFifthMenu: false,
            showSixthMenu: false,
            isLoggedIn: isLoggedIn
        };
        this.showMenu = [
            false, false, false, false, false, false
        ];
    }

/*    getInitialState() {
        console.log('[Frontend] Navbar getInitialState: ' + this.props.isLoggedIn);
        return {
            user_actions : '' ,
            isLoggedIn : this.props.isLoggedIn
        }
    }*/

    handleHoverFirst = (event, ind) => {
        console.log('onMouse - handleHover ' + ind);
        this.setState({ showFirstMenu: true });
    };
    handleLeaveFirst = (event) => {
        this.setState({ showFirstMenu: false });
    };
    handleHoverSecond = (event) => {
        this.setState({ showSecondMenu: true });
    };
    handleLeaveSecond = (event) => {
        this.setState({ showSecondMenu: false });
    };

    handleHover = (event, ind) => {
        this.setState({ showFirstMenu: true });
        this.showMenu[ind] = true;
        console.log('onMouse - handleHover ' + ind + ', this.showMenu: ' + this.showMenu[ind]);
    };
    handleLeave = (event, ind) => {
        this.setState({ showFirstMenu: false });
        this.showMenu[ind] = false;
        console.log('onMouse - handleLeave ' + ind + ', this.showMenu: ' + this.showMenu[ind] + ', event: ' +
            JSON.stringify(event.target.value, null, 4));
    };

    doLogOut = (event, ind) => {
        AuthenticationService.logout();
        let isLoggedIn = AuthenticationService.isUserLoggedIn();
        console.log('[Frontend] Navbar doLogOut: isLoggedIn? ' + isLoggedIn);
        // this.setState({isLoggedIn: isLoggedIn});
        // this.handleLeave(event, ind);
        // console.log('onMouse - handleLeave ' + ind + ', this.showMenu: ' + this.showMenu[ind] + ', event: ' +
        //     JSON.stringify(event.target.value, null, 4));
    };

    handleHoverFirstSub = (event) => {
        this.setState({ showFirstMenuSub: true });
    };

    handleLeaveFirstSub = (event) => {
        this.setState({ showFirstMenuSub: false });
    };

    shouldOpenFirst = () => {
        console.log('shouldOpenFirst: ' + this.state.showFirstMenu + ', showFirstMenuSub: ' + this.state.showFirstMenuSub);
        if ((this.state.showFirstMenu || this.state.showFirstMenuSub ) ||
            (!this.state.showFirstMenu && this.state.showFirstMenuSub)) {
            return true;
        }
        else {
            return false;
        }
    };

    // render: function () {
    //     const { activeItem } = this.state

    icon = (
        <span class="logo">
      <a href="/">
        <img src="./assets/yeon1.jpg" height="80" width="80" alt="text here" /></a>
    </span>
    );

    updateLoggedIn() {
        let isLoggedIn = AuthenticationService.isUserLoggedIn();
        let userName = AuthenticationService.getLoggedInUserName();
        axios.get('/api/ipHistory/isAdmin?email=' + userName) // calendar
            .then(res => {
                this.isAdmin = res.data;
                console.log("[Frontend] Navbar isAdmin from /api/ipHistory/isAdmin: " + JSON.stringify(this.isAdmin));
                this.setState({showScreen: true});
            })
            .catch(error => {
                console.log(error);
                this.isAdmin = false;
                this.setState({showScreen: false});
                console.log("[Frontend] Navbar isAdmin ERROR: " + this.isAdmin);
            });

        this.setState({isLoggedIn: isLoggedIn});
        console.log('[Frontend] Navbar updateLoggedIn: ' + isLoggedIn);
    }

    handleLoggedStatus = () => {
        this.updateLoggedIn();
    }

    checkLoggedIn = () => {
        console.log('[Frontend] Navbar checkLoggedIn: ');
        this.updateLoggedIn();
    }

    componentDidMount() {
        console.log('[Frontend] Navbar componentDidMount: ');
        this.updateLoggedIn();
    }

    componentWillMount() {
        console.log("[Frontend] Navbar AuthenticationService ===componentWillMount===")
        AuthenticationService.setupAxiosInterceptors();
    }

    componentWillReceiveProps(nextProps) {
        console.log('[Frontend] Navbar componentWillReceiveProp nextProps: ' + nextProps.isLoggedIn);
        this.updateLoggedIn();
/*        this.setState({
            isLoggedIn : nextProps.isLoggedIn
        }, function(){
            this.updateLoggedIn();
        });*/
    }

    componentDidUpdate(prevprops, prevstate){
        // console.log('[Frontend] Navbar componentDidUpdate: ');
        // this.updateLoggedIn();
        if (prevstate.isLoggedIn !== this.state.isLoggedIn) {
            console.log('[Frontend] Navbar componentDidUpdate: ');
            this.updateLoggedIn();
        }
    }

    render() {
        console.log('[Frontend] Navbar isLoggedIn from App: ' + this.state.isLoggedIn);
        return (

            // bg="primary" className="navbar-logo"
            // <Icon name='bullhorny' size='large'  />  ...AllStyles.noWrap   sticky="top"

            <div className='mainWrapper' style={{...AllStyles.noWrap, fontFamily: 'Yonsei'}}>
                <Navbar className="navbar-custom m-auto" variant="dark" expand="sm" >
                    <Navbar.Brand className="m-auto" style={{...AllStyles.centerSimple, fontSize: AllStyles.navBrand.fontSize}} as={Link} to='/'>
                        <img
                            src="img/yeon.png"
                            alt="logo"
                            width={AllStyles.navBrand.width}
                            height={AllStyles.navBrand.width}
                            // className="d-inline-block "
                        />&emsp;{Constants.menuMain}
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                    <Navbar.Collapse id="responsive-navbar-nav">

                    {/*<Nav style={{marginLeft: "10px"}}>*/}

                    <Nav style={{...AllStyles.marginFirstItem, fontSize: AllStyles.navMenu.fontSize}} className="navbar-custom m-auto">
                        {/*<Nav.Link href={urlSurvey}>{menuHome}</Nav.Link>*/}
                        {/*                        <NavDropdown title={menuHome} id='nav-first'
                            // onMouseEnter = { this.handleHoverFirst(1) }
                            onMouseEnter = {e => this.handleHover(e, 0)}
                            onMouseLeave = {e => this.handleLeave(e, 0)}
                            as={Link} to={url1Abouts}
                            className="custom-dropdown-btn caret"
                            show = { this.showMenu[0] }
                                      >

                            <NavDropdown.Item as={Link} to={url1Abouts} onClick={e => this.handleLeave(e, 0)}>
                                {menu1Abouts}</NavDropdown.Item>
                            <NavDropdown.Item as={Link} to={url1Greetings} onClick={e => this.handleLeave(e, 0)}>
                                {menu1Greetings}</NavDropdown.Item>
                            <NavDropdown.Item as={Link} to={url1Ceo} onClick={e => this.handleLeave(e, 0)}>
                                {menu1Ceo}</NavDropdown.Item>

                        </NavDropdown>   &emsp;  */}
                        {/*<Nav.Link style={AllStyles.marginNavLink} as={Link} to='/'>{Constants.menuMain}</Nav.Link>*/}
                        <Nav.Link style={AllStyles.marginNavLink} as={Link} to={url1Intro}>{menuHome}</Nav.Link>
                        <Nav.Link style={AllStyles.marginNavLink} as={Link} to={url2Teachers}>{menu2Teachers}</Nav.Link>
                        <Nav.Link style={AllStyles.marginNavLink} as={Link} to={url3Prog}>{menu3Programs}</Nav.Link>
                        {/*<Nav.Link style={AllStyles.marginNavLink} as={Link} to={url3_5Activity}>{menu3_5Programs}</Nav.Link>*/}
                        <Nav.Link style={AllStyles.marginNavLink} as={Link} to={url4TimeTable}>{menu4TimeTable}</Nav.Link>
                        <Nav.Link style={AllStyles.marginNavLink} as={Link} to={url5Register}>{menu5Register}</Nav.Link>
                        {/*<NavDropdown style={{fontSize: '20px', marginLeft: '10px'}} title={menu5Register} id='nav-first'
                                     onMouseEnter = {e => this.handleHover(e, 2)}
                                     onMouseLeave = {e => this.handleLeave(e, 2)}
                                     className="custom-dropdown-btn caret"
                                     show = { this.showMenu[2] } >

                            <NavDropdown.Item as={Link} to={url5aRegisterClass} onClick={e => this.handleLeave(e, 2)}>
                                {menu5aRegisterClass}</NavDropdown.Item>
                            <NavDropdown.Item as={Link} to={url5bRegisterConsult} onClick={e => this.handleLeave(e, 2)}>
                                {menu5bRegisterConsult}</NavDropdown.Item>
                        </NavDropdown>*/}


                        <Nav.Link style={AllStyles.marginNavLink} as={Link} to={url6Location}>{menu6Location}</Nav.Link>
                        {/*<NavDropdown.Item as={Link} to={url6Announce} onClick={e => this.handleLeave(e, 5)}>*/}
                        {/*    {menu6Announce}</NavDropdown.Item>*/}
                        {/*<NavDropdown style={AllStyles.marginNavLink} title={menu6Community} id='nav-first'*/}
                        {/*             onMouseEnter = {e => this.handleHover(e, 5)}*/}
                        {/*             onMouseLeave = {e => this.handleLeave(e, 5)}*/}
                        {/*             className="custom-dropdown-btn caret"*/}
                        {/*             show = { this.showMenu[5] } >*/}

                        {/*    <NavDropdown.Item as={Link} to={url6Location} onClick={e => this.handleLeave(e, 5)}>*/}
                        {/*        {menu6Location}</NavDropdown.Item>*/}
                        {/*    <NavDropdown.Item as={Link} to={url6Announce} onClick={e => this.handleLeave(e, 5)}>*/}
                        {/*        {menu6Announce}</NavDropdown.Item>*/}
                        {/*</NavDropdown>*/}
                        {/*<Nav.Link as={Link} to={urlModal}>modal</Nav.Link>*/}
                        {/*<Nav.Link style={AllStyles.marginNavLink} as={Link} to={urlSignup}>{menuSignup}</Nav.Link>*/}

                        {this.state.isLoggedIn ?
                            <>
                            <Nav.Link style={AllStyles.marginNavLink} as={Link} to={Constants.urlMyInfo}>
                                <Icon name='user outline'/> {Constants.menuMembers}</Nav.Link>
                            <Nav.Link style={AllStyles.marginNavLink} as={Link} to={Constants.urlLogin} onClick={e => this.doLogOut(e, 5)}>
                                <Icon name='sign-out'/> {Constants.menuLogout}</Nav.Link>
                                {this.isAdmin ? <Nav.Link style={AllStyles.marginNavLink} as={Link} to={Constants.url100Visitors}>{menuAdminIp}</Nav.Link> : <></>}
                                {/*{this.isAdmin ? <Nav.Link style={AllStyles.marginNavLink} as={Link} to={Constants.urlAdmin}>{menuAdminUpload}</Nav.Link> : <></>}*/}

                            {this.isAdmin ?
                                    <NavDropdown style={AllStyles.marginNavLink} title={Constants.menuAdminUpload} id='nav-first'
                                         onMouseEnter = {e => this.handleHover(e, 5)}
                                         onMouseLeave = {e => this.handleLeave(e, 5)}
                                         className="custom-dropdown-btn caret"
                                         show = { this.showMenu[5] } >

                                <NavDropdown.Item as={Link} to={Constants.urlAdmin} onClick={e => this.handleLeave(e, 5)}>
                                    <Icon name='image outline'/>&nbsp;{Constants.menuAdminActivity}</NavDropdown.Item>
                                <NavDropdown.Item as={Link} to={Constants.urlAdminAnnounce} onClick={e => this.handleLeave(e, 5)}>
                                    <Icon name='edit outline'/>&nbsp;{Constants.menuAdminAnnounce}</NavDropdown.Item>
                            </NavDropdown> : <></>}
                            </>
                            :

                            <>
                                <Nav.Link style={AllStyles.marginNavLink} as={Link} to={Constants.urlSignup}> <Icon name='user outline'/> {Constants.menuSignup}</Nav.Link>
                                <Nav.Link style={AllStyles.marginNavLink} as={Link} to={Constants.urlLogin} onClick={this.checkLoggedIn.bind(this)}> <Icon name='sign-in'/> {Constants.menuLogin}</Nav.Link>
                            </>

                            /*<NavDropdown style={AllStyles.marginNavLink} title={Constants.menuMyAcct} id='nav-first'
                                         onMouseEnter = {e => this.handleHover(e, 5)}
                                         onMouseLeave = {e => this.handleLeave(e, 5)}
                                         className="custom-dropdown-btn caret"
                                         show = { this.showMenu[5] } >

                                <NavDropdown.Item as={Link} to={Constants.urlLogin} onClick={e => this.handleLeave(e, 5)}>
                                    <Icon name='sign-in'/>&nbsp;{Constants.menuLogin}</NavDropdown.Item>
                                <NavDropdown.Item as={Link} to={Constants.urlSignup} onClick={e => this.handleLeave(e, 5)}>
                                    <Icon name='user outline'/>&nbsp;{Constants.menuSignup}</NavDropdown.Item>
                            </NavDropdown>*/
                        }
                        {/*<Icon name='power off'/>*/}
                        {/*<Nav.Link style={AllStyles.marginNavLink} as={Link} to={Constants.urlLogin}> <Icon name='sign-in'/> {menuLogin}</Nav.Link>*/}

                    </Nav>
                    </Navbar.Collapse>
                    {/*<div style={AllStyles.fontSignup} as={Link} to={urlSignup}>*/}

                    {/*<Form inline>*/}
                    {/*    /!*<FormControl style={{width: 80}} type="text" placeholder="Search" size='small' className="mr-sm-2" />*!/*/}
                    {/*    <Button variant="outline-light">Searcher</Button>*/}
                    {/*</Form>*/}

                </Navbar>
            </div>
        );
    }
}

const NavbarInstance2 = () => {
    return (
            <div className="App" style={{...AllStyles.noWrap}} >
                <div style={{...AllStyles.centerSimple}}>
                <ReactBootStrap.Navbar className="mas-auto" collapseOnSelect expand="md" bg="primary" variant="dark" style={{fontSize: "20px"}}>
                    <ReactBootStrap.Navbar.Brand href="#home">THICC BOIS HOURS</ReactBootStrap.Navbar.Brand>
                    <ReactBootStrap.Navbar.Toggle aria-controls="responsive-navbar-nav" />
                    <ReactBootStrap.Navbar.Collapse id="responsive-navbar-nav">
                        <ReactBootStrap.Nav className="m-auto">
                            <Link to="/features">
                                <ReactBootStrap.Nav.Link href="#features">Features</ReactBootStrap.Nav.Link>
                            </Link>
                            <Link to="/pricing">
                                <ReactBootStrap.Nav.Link href="#pricing">Pricing</ReactBootStrap.Nav.Link>
                            </Link>
                            <Link to="/pricing">
                                <ReactBootStrap.Nav.Link href="#pricing">Pricing</ReactBootStrap.Nav.Link>
                            </Link>
                            <Link to="/pricing">
                                <ReactBootStrap.Nav.Link href="#pricing">Pricing</ReactBootStrap.Nav.Link>
                            </Link>
                            <Link to="/pricing">
                                <ReactBootStrap.Nav.Link href="#pricing">Pricing</ReactBootStrap.Nav.Link>
                            </Link>
                            <Link to="/pricing">
                                <ReactBootStrap.Nav.Link href="#pricing">Pricing</ReactBootStrap.Nav.Link>
                            </Link>
                            <Link to="/pricing">
                                <ReactBootStrap.Nav.Link href="#pricing">Pricing</ReactBootStrap.Nav.Link>
                            </Link>
                            <Link to="/pricing">
                                <ReactBootStrap.Nav.Link href="#pricing">Pricing</ReactBootStrap.Nav.Link>
                            </Link>
                            <Link to="/pricing">
                                <ReactBootStrap.Nav.Link href="#pricing">Pricing</ReactBootStrap.Nav.Link>
                            </Link>
                            <Link to="/pricing">
                                <ReactBootStrap.Nav.Link href="#pricing">Pricing</ReactBootStrap.Nav.Link>
                            </Link>
                            <Link to="/pricing">
                                <ReactBootStrap.Nav.Link href="#pricing">Pricing</ReactBootStrap.Nav.Link>
                            </Link>

                            <ReactBootStrap.NavDropdown title="YEET" id="collapsible-nav-dropdown">
                                <ReactBootStrap.NavDropdown.Item href="#action/3.1">Action</ReactBootStrap.NavDropdown.Item>
                                <ReactBootStrap.NavDropdown.Item href="#action/3.2">Another action</ReactBootStrap.NavDropdown.Item>
                                <ReactBootStrap.NavDropdown.Item href="#action/3.3">Something</ReactBootStrap.NavDropdown.Item>
                                <ReactBootStrap.NavDropdown.Divider />
                                <ReactBootStrap.NavDropdown.Item href="#action/3.4">Separated link</ReactBootStrap.NavDropdown.Item>
                            </ReactBootStrap.NavDropdown>
                        </ReactBootStrap.Nav>
{/*                        <ReactBootStrap.Nav>
                            <Link to="/deets">
                                <ReactBootStrap.Nav.Link href="#deets">More deets</ReactBootStrap.Nav.Link>
                            </Link>
                            <Link to="/dankmemes">
                                <ReactBootStrap.Nav.Link eventKey={2} href="#memes">
                                    Dank memes
                                </ReactBootStrap.Nav.Link>
                            </Link>
                        </ReactBootStrap.Nav>*/}
                    </ReactBootStrap.Navbar.Collapse>
                </ReactBootStrap.Navbar>
                </div>
        </div>
    )
}

const NavLinksBar = () => (
<div className='mainWrapper'>
{/*    <div style={{display: "flex", justifyContent: "flex-end", marginRight: "50px"}}>
    <Icon name='sign-in' size={"large"}/><Link to={Constants.urlLogin}>{menuLogin}</Link>
    </div>*/}

<Navbar className="navbar-custom" variant="dark" sticky="top">
    <Navbar.Brand style={{fontSize: "30px"}} as={Link} to='/'>
        <img
            src="img/yeon.png"
            alt="logo"
            width="80"
            height="80"
            className="d-inline-block "
        />&emsp;{Constants.menuMain}
    </Navbar.Brand>

    {/*<Nav style={{marginLeft: "10px"}}>*/}
    <Nav style={{...AllStyles.marginFirstItem, ...AllStyles.noWrap}} className="mr-auto">
        {/*<Nav.Link href={urlSurvey}>{menuHome}</Nav.Link>*/}
        {/*                        <NavDropdown title={menuHome} id='nav-first'
                            // onMouseEnter = { this.handleHoverFirst(1) }
                            onMouseEnter = {e => this.handleHover(e, 0)}
                            onMouseLeave = {e => this.handleLeave(e, 0)}
                            as={Link} to={url1Abouts}
                            className="custom-dropdown-btn caret"
                            show = { this.showMenu[0] }
                                      >

                            <NavDropdown.Item as={Link} to={url1Abouts} onClick={e => this.handleLeave(e, 0)}>
                                {menu1Abouts}</NavDropdown.Item>
                            <NavDropdown.Item as={Link} to={url1Greetings} onClick={e => this.handleLeave(e, 0)}>
                                {menu1Greetings}</NavDropdown.Item>
                            <NavDropdown.Item as={Link} to={url1Ceo} onClick={e => this.handleLeave(e, 0)}>
                                {menu1Ceo}</NavDropdown.Item>

                        </NavDropdown>*/}
        <Nav.Link style={AllStyles.marginNavLink} as={Link} to={url1Intro}>{menuHome}</Nav.Link>
        <Nav.Link style={AllStyles.marginNavLink} as={Link} to={url2Teachers}>{menu2Teachers}</Nav.Link>

        <Nav.Link style={AllStyles.marginNavLink} as={Link} to={url3Prog}>{menu3Programs}</Nav.Link>

        {/*<NavDropdown style={{marginLeft: "10px"}} title={menu3Programs} id='nav-first'
                             onMouseEnter = {e => this.handleHover(e, 2)}
                             onMouseLeave = {e => this.handleLeave(e, 2)}
                             className="custom-dropdown-btn caret"
                             show = { this.showMenu[2] } >

                            <NavDropdown.Item as={Link} to={url3ProgInfant} onClick={e => this.handleLeave(e, 2)}>
                                {menu3Infant}</NavDropdown.Item>
                            <NavDropdown.Item as={Link} to={url3ProgInfant} onClick={e => this.handleLeave(e, 2)}>
                                {menu3Kinder}</NavDropdown.Item>
                        </NavDropdown>*/}
        <Nav.Link style={AllStyles.marginNavLink} as={Link} to={url4TimeTable}>{menu4TimeTable}</Nav.Link>
        <Nav.Link style={AllStyles.marginNavLink} as={Link} to={url5Register}>{menu5Register}</Nav.Link>
        <Nav.Link style={AllStyles.marginNavLink} as={Link} to={url6Location}>{menu6Location}</Nav.Link>
        {/*<NavDropdown.Item as={Link} to={url6Announce} onClick={e => this.handleLeave(e, 5)}>*/}
        {/*    {menu6Announce}</NavDropdown.Item>*/}
        {/*<NavDropdown style={AllStyles.marginNavLink} title={menu6Community} id='nav-first'*/}
        {/*             onMouseEnter = {e => this.handleHover(e, 5)}*/}
        {/*             onMouseLeave = {e => this.handleLeave(e, 5)}*/}
        {/*             className="custom-dropdown-btn caret"*/}
        {/*             show = { this.showMenu[5] } >*/}

        {/*    <NavDropdown.Item as={Link} to={url6Location} onClick={e => this.handleLeave(e, 5)}>*/}
        {/*        {menu6Location}</NavDropdown.Item>*/}
        {/*    <NavDropdown.Item as={Link} to={url6Announce} onClick={e => this.handleLeave(e, 5)}>*/}
        {/*        {menu6Announce}</NavDropdown.Item>*/}
        {/*</NavDropdown>*/}
        {/*<Nav.Link as={Link} to={urlModal}>modal</Nav.Link>*/}
        <Nav.Link style={AllStyles.marginNavLink} as={Link} to={urlSignup}>{menuSignup}</Nav.Link>
        <Nav.Link style={AllStyles.marginNavLink} as={Link} to={Constants.urlLogin}> <Icon name='sign-in'/> {menuLogin}</Nav.Link>
        {/*<Icon name='power off'/>*/}

    </Nav>
    {/*<div style={AllStyles.fontSignup} as={Link} to={urlSignup}>*/}

    {/*<Form inline>*/}
    {/*    /!*<FormControl style={{width: 80}} type="text" placeholder="Search" size='small' className="mr-sm-2" />*!/*/}
    {/*    <Button variant="outline-light">Searcher</Button>*/}
    {/*</Form>*/}
</Navbar>
</div>
);

const AnimatedPage = (location) => (
    <Switch location={location}>
        <Route exact path='/' component={Home} />
        <Route exact path={url1Intro} component={Intro} />
        <Route exact path={urlSignup} component={Signup} />
        <Route exact path={url1Greetings} component={Greetings} />

        <Route exact path={url2Teachers} component={Teachers} />
        <Route exact path={url3Prog} component={Program} />
        <Route exact path={url4TimeTable} component={TimeTable} />
        <Route exact path={url5Register } component={Register} />
        <Route exact path={url6Announce } component={Announce} />
        <Route exact path={url6Location } component={Location} />
    </Switch>
);

export {NavbarInstance as NavLinksBar, AnimatedPage};  // NavbarEng

// export default NavbarInstance;
